

























// import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "SelfSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    swiperData: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "440px",
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          //  clickable: true, //允许分页点击跳转
        },
        // observer: true,
        // observeParents: true,
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      },
    };
  },
};
