import { render, staticRenderFns } from "./newsimg.vue?vue&type=template&id=271668fd&scoped=true&"
import script from "./newsimg.vue?vue&type=script&lang=ts&"
export * from "./newsimg.vue?vue&type=script&lang=ts&"
import style0 from "./newsimg.vue?vue&type=style&index=0&id=271668fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271668fd",
  null
  
)

export default component.exports