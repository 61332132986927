import Vue from "vue";
import App from "./App.vue";
import VueCompositionAPI from "@vue/composition-api";
import AmapVue from "@amap/amap-vue";
import skeleton from "@/components/skeleton/index.vue";

import "@/common/css/index.scss";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
AmapVue.config.version = "2.0";
AmapVue.config.key = "ddd292c88aa1bad9c04891a47724f40a";
// "4f6eaf76e16c04660864231963aa037c";
Vue.use(AmapVue);
Vue.use(VueCompositionAPI);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.component("skeleton", skeleton); // 注册全局组件

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
