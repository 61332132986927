import { reactive, watch, ref } from "@vue/composition-api";
import router from "@/router";
import { getListToType } from "@/api/index";
export function useList(type: string, fn?: Function) {
  const { query } = router.currentRoute;
  const state = reactive<IProps>({
    loading: false,
    list: [],
    total: 0,
    listQuery: {
      pageNum: 1,
      pageSize: 10,
    },
    infoData: {}, // 详情数据处理
    isDetails: false, // 判断是否是详情，true 则是详情；false 则是列表
    // activeId: 0, // 目录id
  });
  // 点击当前目录Active
  const meunActive = reactive({
    value: {}, // 目录
    activeId: 0, // 目录id
  });

  let Mdata: any = ref([]);
  // 根据URL参数查找目录
  function activeValue(data: any, id: number) {
    for (const obj of data) {
      if (obj.id == id) {
        meunActive.value = obj;
        return obj;
      }
      if (obj.children) {
        activeValue(obj.children, id);
      }
    }
  }

  // 加载目录
  const getListMenuFn = async () => {
    return new Promise((reslove) => {
      getListToType(type)
        .then((res) => {
          Mdata.value = Fn(res.data.list);
          meunActive.value = Mdata.value[0];
          meunActive.activeId = query.menuactive
            ? Number(query.menuactive)
            : Mdata.value[0].id;
          activeValue(Mdata.value, Number(query.menuactive)); // 带有URL参数menuactive,进行查找，否则默认第一个
          reslove(meunActive.value);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // 格式化数据
  const Fn = (arr: IProps) => {
    arr.forEach((item: IProps) => {
      item.label = item.name;
      item.id = Number(item.id);
      if (item.children) {
        Fn(item.children);
      }
    });
    return arr;
  };

  // 加载数据
  async function getTableListData() {
    if (fn && typeof fn === "function") {
      state.loading = true;
      const { items, count } = await fn().finally(() => {
        setTimeout(() => {
          state.loading = false;
        }, 800);
      });
      state.total = count;
      state.list = items;
    }
  }

  watch(state.listQuery, () => {
    getTableListData();
  });
  // 重置状态
  function resetState() {
    state.listQuery.pageNum = 1;
    state.listQuery.pageSize = 10;
  }

  // 目录
  const nodeClick = (val: any) => {
    state.activeId = val.id;
    state.isDetails = false; // 点击目录
  };

  return {
    query,
    state,
    getTableListData,
    resetState,
    nodeClick,
    getListMenuFn,
    Mdata,
    meunActive,
  };
}

export function useDom() {
  const { query } = router.currentRoute;
  const offcanvas: any = ref(null);
  // 跳转页面
  function skipPage(path: string, query: Record<string, any>) {
    router.push({
      path,
      query: { ...query },
    });
  }
  const showOffcanvasMenu = () => {
    offcanvas.value.showOffcanvasMenu();
  };
  // 不刷新，修改url后面参数
  const changeURLStatic = (name: string, value: string) => {
    const url = location.href;
    const reg = eval("/([?|&]" + name + "=)[^&]*/gi");
    value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); //移除首尾空格
    let url2 = "";
    if (!value) {
      url2 = url.replace(reg, ""); //正则替换
    } else {
      if (url.match(reg)) {
        url2 = url.replace(reg, "$1" + value); //正则替换
      } else {
        url2 = url + (url.indexOf("?") > -1 ? "&" : "?") + name + "=" + value; //没有参数添加参数
      }
    }
    history.replaceState(null, "", url2);
  };

  return {
    skipPage,
    showOffcanvasMenu,
    offcanvas,
    changeURLStatic,
    // nodeClick,
    // ...toRefs(meunActive),
    query,
  };
}
