




















































import { defineComponent, onMounted, ref } from "@vue/composition-api";
// import { FootData } from "./form";
import { getFootData } from "@/api/index";
import { useDom } from "@/use/getData";
export default defineComponent({
  name: "Foot",
  setup(props, { emit }) {
    const FootData = ref({});
    const { skipPage } = useDom();
    onMounted(() => {
      getFootData().then((res) => {
        FootData.value = res.data;
      });
    });

    const handleRouter = (row: IProps) => {
      emit("reload");
      skipPage(row.router, {});
    };
    return {
      FootData,
      handleRouter,
    };
  },
});
