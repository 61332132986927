<template>
  <div class="portfolio-item">
    <div class="caption">
      <div class="caption-content">
        <div class="h2">{{ value.title }}</div>
        <p class="mb-0">
          {{ value.value }}
        </p>
      </div>
    </div>
    <img
      class="img-fluid"
      src="https://cn.bing.com/th?id=OHR.BorregoBadlands_ZH-CN9913349081_UHD.jpg&rf=LaDigue_UHD.jpg"
      alt="..."
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
  cursor: pointer;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.portfolio-item .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem;
}
.portfolio-item .caption .caption-content h2,
.portfolio-item .caption .caption-content .h2 {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    transition: background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, background-color 0.7s;
    transition: clip-path 0.25s ease-out, background-color 0.7s,
      -webkit-clip-path 0.25s ease-out;
    clip-path: inset(0px);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .portfolio-item img {
    transition: -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, -webkit-clip-path 0.25s ease-out;
    clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    clip-path: inset(1rem);
  }
  .portfolio-item:hover .caption {
    background-color: rgba(34, 34, 34, 0.9);
    clip-path: inset(1rem);
  }
}
</style>
