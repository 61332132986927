















































import {
  onMounted,
  ref,
  watchEffect,
  inject,
  watch,
} from "@vue/composition-api";
import router from "@/router";
import { useDom } from "@/use";

export default {
  setup(props, { emit }) {
    const info = [
      {
        name: "网站首页",
        value: "/",
        active: "home",
      },
      {
        name: "关于我们",
        value: "/aboutus",
        active: "aboutus",
      },
      {
        name: "产品中心",
        value: "/products",
        active: "products",
      },
      // {
      //   name: "新闻资讯",
      //   value: "/news",
      //   active: "news",
      // },
      {
        name: "定制服务",
        value: "/custom-service",
        active: "custom",
      },
      {
        name: "解决方案",
        value: "/solution",
        active: "solution",
      },
      // {
      //   name: "支持与服务",
      //   value: "/service",
      //   active: "service",
      // },
      {
        name: "联系我们",
        value: "/contactus",
        active: "contactus",
      },
    ];
    const { skipPage } = useDom();
    const { meta, query }: IProps = router.currentRoute;

    let search: any = ref(localStorage.getItem("query")); // 搜索key
    let active = ref(""); // 当前info标识目录active

    onMounted(async () => {
      router.beforeEach((to, from, next) => {
        // console.log("跳转的路径", to);
        const { meta } = to as IProps;
        active.value = meta.active || "";
        next();
      });
      // active.value = meta.active || "";
      // console.log(meta);
    });

    const handleRouter = (row: any) => {
      active.value = row.active;
      emit("reload");
      router.push({
        path: row.value,
      });
      localStorage.removeItem("query");
      search.value = "";
    };
    //  搜索
    const handleSearch = () => {
      active.value = "";
      localStorage.setItem("query", search.value);
      emit("reload");
      skipPage("/search", {
        value: search.value,
      });
    };
    // 切换目录active状态
    const handleActive = (activevalue: string) => {
      if (activevalue) {
        active.value = activevalue;
      }
    };

    return {
      info,
      handleRouter,
      search,
      handleSearch,
      active,
      img: "img/logo.jpg",
      handleActive,
    };
  },
};
