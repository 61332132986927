

















import { defineComponent, ref, watch } from "@vue/composition-api";
import { getBannerPage } from "@/api/index";

export default defineComponent({
  name: "bgImg",
  props: {
    src: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "180px",
    },
    code: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const url = ref(props.src);
    const getBannerPageFn = () => {
      getBannerPage(props.code)
        .then((res) => {
          url.value = res.data.imgUrl;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    watch(
      () => props.code,
      () => {
        getBannerPageFn();
      },
      { immediate: true }
    );
    return {
      url,
    };
  },
});
