







import {
  defineComponent,
  onMounted,
  provide,
  ref,
  nextTick,
  watch,
} from "@vue/composition-api";
import Nav from "@/components/nav/index.vue";
import Foot from "@/components/foot/index.vue";
export default defineComponent({
  components: {
    Nav,
    Foot,
  },
  setup() {
    const isRouterAlive = ref(true);
    // 刷新页面
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(function () {
        isRouterAlive.value = true;
      });
    };
    const Nav: IProps = ref(null);
    provide("reload", reload);
    return {
      isRouterAlive,
      reload,
      Nav,
    };
  },
});
