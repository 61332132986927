

























































import { defineComponent, ref } from "@vue/composition-api";
import { useDom } from "@/use";
export default defineComponent({
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const { skipPage } = useDom();
    const handleRouter = (row: IProps) => {
      skipPage(`/news-info`, {
        type: "details",
        id: row.value,
        menuactive: row.id,
      });
    };
    const stateTip = ref(false);
    const handleTip = () => {
      stateTip.value = !stateTip.value;
    };
    return {
      stateTip,
      handleTip,
      handleRouter,
    };
  },
});
