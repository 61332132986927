import request from "../../utils/request";

// 根据id获取详细内容
export function getContent(id: number): Promise<any> {
  return request({
    url: `/index/getContent/${id}`,
    method: "get",
  });
}

// 获取列表
export function getList(params: IProps): Promise<any> {
  return request({
    url: `/index/getContentList`,
    method: "get",
    params,
  });
}

// 轮播图
export function getBanner(): Promise<any> {
  return request({
    url: `/index/getBanner`,
    method: "get",
  });
}

// 获取海报
export function getBannerPage(code: string): Promise<any> {
  return request({
    url: `/index/getBanner/${code}`,
    method: "get",
  });
}

// 获取推荐
export function getGoods(): Promise<any> {
  return request({
    url: `/index/getGoods`,
    method: "get",
  });
}

// 搜索
export function getSearch(params: IProps): Promise<any> {
  return request({
    url: `/index/search`,
    method: "get",
    params,
  });
}

// 根据类型查询字典表
export const getListToType = (type: string) =>
  request({
    url: `/index/getListToType`,
    method: "get",
    params: {
      type,
    },
  });

// 用于获取推荐信息集合
export const getRecommendList = () =>
  request({
    url: `/index/getRecommendList`,
    method: "get",
  });

// 根据code查询字典表
export const getValueByCode = (params: { code: string; type?: string }) =>
  request({
    url: `/index/getValueByCode`,
    method: "get",
    params,
  });

// 查询信息
export function getNews(): Promise<any> {
  return request({
    url: `/index/getNews`,
    method: "get",
  });
}

// 首页信息加载
export function getSolution(): Promise<any> {
  return request({
    url: "/index/getSolution",
    method: "get",
  });
}

// 获取公司地址等等信息
export function getContactUs(): Promise<any> {
  return request({
    url: "/index/getContactUs",
    method: "GET",
  });
}

export function getFootData(): Promise<any> {
  return request({
    url: "/index/getFootData",
    method: "GET",
  });
}
