








































































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const tipData = [
      {
        class: "ipzb0",
        title: "OEM/ODM定制服务",
        tip: "个性化   多样化",
      },
      {
        class: "ipzb1",
        title: "工业平板电脑",
        tip: "精准触控   军工品质",
      },
      {
        class: "ipzb2",
        title: "工业显示器",
        tip: "高清亮屏   多重安装",
      },
      {
        class: "ipzb3",
        title: "工业服务器",
        tip: "工业级   高性能",
      },
      {
        class: "ipzb4",
        title: "工业一体化工作站",
        tip: "坚固  灵活  易管理",
      },
      {
        class: "ipzb5",
        title: "嵌入式工控机",
        tip: "小体积   低功耗",
      },
      {
        class: "ipzb6",
        title: "工控整机",
        tip: "扩展性好   通信功能强",
      },
      {
        class: "ipzb7",
        title: "工控主板",
        tip: "个性能稳定   安全可靠",
      },
    ];
    return {
      tipData,
    };
  },
});
