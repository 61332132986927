



















































































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import SelfSwiper from "@/components/swiper/index.vue";
import Carousel from "@/components/carousel/index.vue";
import Portfolio from "@/components/portfolio/index.vue";
import Foot from "@/components/foot/index.vue";

import NewList from "./components/newlist.vue";
import Tip from "./components/tip.vue";
import Ibbox from "./components/ibbox.vue";
// import News from "./components/news.vue";
import NewsImg from "./components/newsimg.vue";
import {
  getRecommendList,
  getBanner,
  getListToType,
  getNews,
  getSolution,
} from "@/api/index";

export default defineComponent({
  components: {
    Carousel,
    Portfolio,
    Foot,
    Ibbox,
    // News,
    SelfSwiper,
    NewList,
    Tip,
    NewsImg,
  },
  setup() {
    const goodinfo = [
      {
        title: "OEM/ODM定制服务",
        value: "个性化   多样化",
      },
      {
        title: "工业平板电脑",
        value: "精准触控   军工品质",
      },
      {
        title: "工业显示器",
        value: "高清亮屏   多重安装",
      },
      {
        title: "OEM/ODM定制服务",
        value: "个性化   多样化",
      },
      {
        title: "工业平板电脑",
        value: "精准触控   军工品质",
      },
      {
        title: "工业显示器",
        value: "高清亮屏   多重安装",
      },
      {
        title: "工业显示器",
        value: "高清亮屏   多重安装",
      },
      {
        title: "工业显示器",
        value: "高清亮屏   多重安装",
      },
    ];
    let Enterprise: any = ref([]); //  企业新闻
    let Industry: any = ref([]); // 行业新闻
    let news: any = ref([]); // 新闻资讯
    let swiperData = ref([]); // 轮播图

    onMounted(async () => {
      getBannerFn();
      getRecommendList().then((res) => {
        console.log(res);
      });
      // getNewsFn();
      // Industry.value = await getListToFn("news-1"); // 行业新闻
      // Enterprise.value = await getListToFn("news-0"); // 企业新闻
      getSolutionFn();
    });

    const getBannerFn = () => {
      getBanner().then((res) => {
        swiperData.value = res.data;
      });
    };
    // 获取新闻
    function getListToFn(code: string) {
      return new Promise((reslove) => {
        getListToType(code).then((res) => {
          reslove(res.data.list);
        });
      });
    }

    // 获取“新闻资讯”
    // const getNewsFn = () => {
    //   console.log(123123);
    //   getNews()
    //     .then((res) => {
    //       console.log(res);
    //       news.value = res.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };
    const getSolutionFn = () => {
      getSolution().then((res) => {
        news.value = res.data;
      });
    };

    return {
      goodinfo,
      swiperData,
      Enterprise,
      Industry,
      news,
    };
  },
});
