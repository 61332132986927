import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/home/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      active: "home",
    },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "AboutUs" */ "../views/aboutus/index.vue"),
    meta: {
      active: "aboutus",
    },
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () =>
      import(
        /* webpackChunkName: "ContactUs" */ "../views/contactus/index.vue"
      ),
    meta: {
      active: "contactus",
    },
  },
  {
    path: "/custom-service",
    name: "CustomService",
    component: () =>
      import(
        /* webpackChunkName: "CustomService" */ "../views/custom-service/index.vue"
      ),
    meta: {
      active: "custom",
    },
  },
  // {
  //   path: "/news",
  //   name: "News",
  //   component: () =>
  //     import(/* webpackChunkName: "News" */ "../views/news/index.vue"),
  //   meta: {
  //     active: "news",
  //   },
  // },
  // {
  //   path: "/news-info",
  //   name: "News-info",
  //   component: () =>
  //     import(/* webpackChunkName: "News-info" */ "../views/news/info.vue"),
  //   meta: {
  //     active: "news",
  //   },
  // },
  {
    path: "/products",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "Products" */ "../views/products/index.vue"),
    meta: {
      active: "products",
    },
  },
  {
    path: "/products-info",
    name: "Products-info",
    component: () =>
      import(
        /* webpackChunkName: "Products-info" */ "../views/products/info.vue"
      ),
    meta: {
      active: "products",
    },
  },
  // {
  //   path: "/service",
  //   name: "Service",
  //   component: () =>
  //     import(/* webpackChunkName: "Service" */ "../views/service/index.vue"),
  // },
  // {
  //   path: "/service-info",
  //   name: "Service-info",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Service-info" */ "../views/service/info.vue"
  //     ),
  //   meta: {
  //     active: "service",
  //   },
  // },
  {
    path: "/solution",
    name: "Solution",
    component: () =>
      import(/* webpackChunkName: "Solution" */ "../views/solution/index.vue"),
    meta: {
      active: "solution",
    },
  },
  {
    path: "/solution-info",
    name: "Solution-info",
    component: () =>
      import(
        /* webpackChunkName: "Solution-info" */ "../views/solution/info.vue"
      ),
    meta: {
      active: "solution",
    },
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/seachPage/index.vue"),
    meta: {
      active: "search",
    },
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
