







































import { useDom } from "@/use";
import bgImg from "@/components/bgImg/index.vue";
export default {
  name: "news",
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: {
        contents: [],
        imgUrl: "",
        id: "",
      } as IProps,
    },
    menuId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    bgImg,
  },
  setup(props) {
    const { skipPage } = useDom();
    const handleRouter = (row: IProps) => {
      console.log(row);
      skipPage(`/solution-info`, {
        type: "details",
        id: row.id, //row.ids, // 信息详情id
        menuactive: props.menuId, // 目录id
      });
    };
    return {
      handleRouter,
    };
  },
};
