import { getListToType, getContent, getList } from "@/api/index";
import {
  ref,
  onMounted,
  reactive,
  onBeforeMount,
  nextTick,
} from "@vue/composition-api";
import router from "@/router";
// 获取侧目录加载详情

/**
 * type:接口加载code ， LoadingType；页面加载类型“list”则为分页加载
 * */
export function getListToTypeFn(type: string, LoadingType?: string) {
  const { query, meta } = router.currentRoute;

  // 详情内容
  const contentData = reactive({
    content: "", //
    createdTime: "",
    contentTitle: "", // 标题
    description: "",
    swiperData: [],
    refresh: false,
  }) as IProps;
  // 点击当前目录Active
  const meunActive = reactive({
    value: {}, // 目录
    activeId: 0, // 目录id
  });
  let Mdata: any = ref([]);

  // 根据URL参数查找目录
  function activeValue(data: any, id: number) {
    for (const obj of data) {
      if (obj.id == id) {
        meunActive.value = obj;
        return obj;
      }
      if (obj.children) {
        activeValue(obj.children, id);
      }
    }
  }

  onMounted(async () => {
    console.log(meta);
    await getListMenuFn();
    if (LoadingType !== "list") {
      // id 详细id；menuactive 目录字段value值
      const { id, menuactive } = query;
      const _m = menuactive
        ? menuactive
        : Mdata.value[0].value || Mdata.value[0].id;
      const _v = id ? id : _m;
      await getContentFn(_v);
      nextTick(() => {
        // 重新渲染组件
        contentData.refresh = true;
      });
    }
  });
  // 加载目录
  const getListMenuFn = async () => {
    return new Promise((reslove) => {
      getListToType(type)
        .then((res) => {
          Mdata.value = Fn(res.data.list);
          meunActive.value = Mdata.value[0];
          meunActive.activeId = query.menuactive
            ? Number(query.menuactive)
            : Mdata.value[0].id;
          activeValue(Mdata.value, Number(query.menuactive)); // 带有URL参数menuactive,进行查找，否则默认第一个
          reslove(meunActive.value);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  // 格式化数据
  const Fn = (arr: IProps) => {
    arr.forEach((item: IProps) => {
      item.label = item.name;
      item.id = Number(item.id);
      if (item.children) {
        Fn(item.children);
      }
    });
    return arr;
  };
  // 获取数据列表
  // const getListFn = async (id: number) => {
  //   getList();
  // };
  // 获取内容详情
  const getContentFn = async (id: number) => {
    await getContent(id).then((res) => {
      const {
        content,
        createdTime,
        title,
        description,
        img1Url,
        img2Url,
        img3Url,
      } = {
        content: "",
        createdTime: "",
        title: "",
        description: "",
        img1Url: "",
        img2Url: "",
        img3Url: "",
        ...res.data,
      } as IProps;
      contentData.content = content;
      contentData.createdTime = createdTime;
      contentData.contentTitle = title;
      contentData.description = description;
      contentData.swiperData = [] as any;
      if (img1Url) {
        contentData.swiperData.push({
          imgUrl:
            // "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1114%2F113020142315%2F201130142315-1-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656423060&t=35c4eae0fcfbae339b8ac4ac7e76d0ee",
            img1Url,
        });
      }
      if (img2Url) {
        contentData.swiperData.push({
          imgUrl:
            //   "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242306111155-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656423153&t=5ff99949d411fbe5264ebc549e77c466",
            img1Url,
        });
        // contentData.swiperData.push({
        //   imgUrl:
        //     "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242306111155-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1656423153&t=5ff99949d411fbe5264ebc549e77c466",
        //   //img1Url,
        // });
        // contentData.swiperData.push({
        //   imgUrl: img2Url,
        // });
      }
      if (img3Url) {
        contentData.swiperData.push({
          imgUrl: img3Url,
        });
      }
    });
  };

  // 目录点击
  const nodeClick = (val: any) => {
    meunActive.activeId = val.id;
    meunActive.value = val;
    getContentFn(val.value || val.id);
  };
  return {
    Mdata,
    getContentFn,
    contentData,
    nodeClick,
    meunActive,
    getListMenuFn,
    // state,
    query,
  };
}
